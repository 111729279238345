.header {
    position: relative;
    display: flex;
    width: 100%;
    z-index: 20;
    justify-content: space-between;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    background: rgb(17, 40, 73);
    background: linear-gradient(0deg, #112849 0%, #153d6d 100%);
}

.logo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    justify-content: flex-start;

    .logo {
        display: flex;
        height: 3.5rem;
        margin: 0.75rem;
    }
}

.payment-wrapper {
    min-width: 12rem;
    color: #fff;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;

    .desc {
        position: relative;
        display: flex;    
        align-items: center;
        margin: 0;
        margin-right: 0.5rem;
        margin-bottom: .15rem;
        margin-top: 0.35rem;
    }

    .ico-fish{
        position: absolute;
        top: -.8rem;
        left: -2.75rem;
        font-size: 2.5rem;
        color: #7ec0e3;
        // padding-right: .5rem;
        // transform: rotate(-180deg);
        animation: fishrotate 1s linear infinite;
        
    }
}

.amount-wrapper {
    display: flex;
    flex-direction: row;

    .amount {
        position:  relative;
        overflow: hidden;
        font-size: 1.2rem;
        margin: 0.25rem 0.45rem;
        padding: 0.35rem 0.25rem;
        border: 2px solid #7ec0e3;
        border-radius: 0.5rem;
        color: #7ec0e3;
        transition: all 0.1s ease-in;

        &:hover {
            color: #17a2b8;
            border-color: #17a2b8;
        }
    }

    .fee{
        position: relative;
        z-index: 1;
    }
    
    .ico-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        font-size:2rem;
        z-index: 0;
        color: red;
    }
}


@media screen and (min-width: 768px) {
    .logo-wrapper {
        justify-content: flex-start;
        .logo {
            height: 4.5rem;
            margin: 1rem 0rem 0.5rem 1.5rem;
        }
    }

    .payment-wrapper {
        min-width: 20rem;
        padding-right: .75rem;
    }
    
    .amount-wrapper {
        .amount {
            font-size: 1.25rem;
            margin-top: .25rem;
            margin-left: .5rem;
            padding: 0.5rem;
        }
    }
}



@keyframes fishrotate {
    0% {
        transform: translateX(-2px) translateY(0px) rotate(-190deg);
    }
    25% {
        transform: translateX(0px) translateY(0px) rotate(-180deg);
    }
    50% {
        transform: translateX(2px) translateY(0px) rotate(-190deg);
    }
    75% {
        transform: translateX(0px) translateY(0px) rotate(-200deg);
    }
    100% {
        transform: translateX(-2px) translateY(0px) rotate(-190deg);
    }
  }