.counter-wrapper{
    display: flex;
    align-self: center;
    justify-self: flex-end;
    margin: 0 .75rem;
    padding: 0rem;
    color: #fff;
}

@media screen and (min-width: 768px) {
    .counter-wrapper{
        align-self: center;
        justify-content: center;
        margin: 0;
    }
}
