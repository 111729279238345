.footer {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    left: auto
}

.hashtag-wrapper {
    position: fixed;
    bottom: 1.25rem;
    left: 50%;
    width: 13rem;
    margin-left: -4rem;
    padding: .5rem .25rem .75rem .25rem;
    background: #112853;
    border-radius: .5rem;
    text-align: center;
    .hashtag {
        width: 9rem;
    }
}

.right-wrapper{
    position: absolute;
    right: 1rem;
}

.toast-wrapper {
    position: absolute;
    right: 0;
    min-width: 19rem;
    bottom: 3.5rem;
}

.description{
    margin: 0;
}

.btns-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;

    .indicator{
        width: 1.75rem;
        margin-right: .25rem;
    }

    .btn {
        overflow: hidden;
        position: relative;
        margin-left: 1rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        padding: .5rem;

        .counter-wrapper{
            position: relative;
            right: 0;
            z-index: 0;
            margin: 0;
            font-size: 1rem;
        }

        .icosnow{
            position: absolute;
            left: 0;
            font-size: 2.25rem;
            color: #117a8b;
            z-index: 0;
        }
    }
}
