@import "icofont.css";
@import "variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap";

html,
body,
#root {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    font-size: 0.95rem;
    line-height: 1.25rem;
    font-family: "Roboto", sans-serif;
}

a {
  color: #0092ca;
  text-decoration: none;

  &:hover{
    text-decoration: none;
  }
}

