.map-wrapper {
    overflow: hidden;
    width: 100%;
    height: calc(100% - 20px);
    border: none;

    .map {
        position: relative;
        overflow: hidden;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: -54px;
        border: none;
    }
}

@media screen and (min-width: 768px) {
    .map-wrapper {
        height: calc(100% - 36px);
    }
}
